import { useState, useRef, useEffect } from "react";
import { HandySvg } from "handy-svg";
import Image from "next/image";
import styles from "./survey.module.scss";
import useClickOutside from "../../utils/hooks/useClickOutside";
import { useStore } from "../../stores/use_global_state";
import { sendSurveyDataToAirtable } from "../../pages/api/airtable";
import { bodyActiveScroll, bodyHiddenScroll } from "../../utils/bodyToggleHiddenScroll";

const TextareaItem = ({ text = "", changeValue, value, placeholder = false, autoFocus = true }) => {
	return (
		<div className={styles.survey_content}>
			<p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
			<textarea autoFocus={autoFocus} placeholder={placeholder} className={styles.textarea} value={value} onChange={(e) => changeValue(e.target.value)} />
		</div>
	);
};

const InputItem = ({ text = "", type = "text", changeValue, value, placeholder = false, autoFocus = true }) => {
	return (
		<div className={styles.survey_content}>
			<p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
			<input type={type} autoFocus={autoFocus} placeholder={placeholder} className={styles.input} value={value} onChange={(e) => changeValue(e.target.value)} />
		</div>
	);
};

const Survey = ({ title = false, btn = false, close = () => {}, children, isDisable = false, position = "servey" }) => {
	const item = useRef(null);

	useClickOutside(item, () => close(), false);

	return (
		<div className={`${styles.survey_wrapper} ${isDisable ? styles["disable"] : styles["enable"]} ${styles[position]}`}>
			<div className={`${styles.survey_item} ${title !== false ? styles["bg-top"] : undefined}`} ref={item}>
				<button className={styles.close} aria-label="close" onClick={close}>
					<HandySvg src={"/survey/close.svg"} width={16.5} height={16.5} />
				</button>
				{title !== false && <div className={styles.survey_title}>{title}</div>}
				{children}
				{btn && (
					<button id="survey-btn" onClick={btn.callback} className={styles.btn} disabled={isDisable || btn.isDisable}>
						{btn.name}
					</button>
				)}
			</div>
		</div>
	);
};

const PaymentSurvey = ({ close = () => {}, dictionary }) => {
	const { state } = useStore();
	const { accountData } = state;

	const [step, setStep] = useState("one"); // one, twoGod, twoBad, three
	const [rating, setRating] = useState(); // 1 to 5
	const [answer, setAnswer] = useState(""); // text

	const [isDisable, setIsDisable] = useState(false);

	const sendSurveyData = async () => {
		setIsDisable(true);

		const data = { id: accountData.id, rating, answer };
		await sendSurveyDataToAirtable(data, "tblra960KhqXhdn3r");

		setStep("three");

		setIsDisable(false);
	};

	const getTitle = (step) => {
		if (step === "one") return dictionary["survey_1_header"] || "Congrats!";
		if (step === "twoGod" || step === "twoBad") return <HandySvg src={"/survey/question.svg"} width={77} height={76} />;
		if (step === "three") return <HandySvg src={"/survey/ok.svg"} width={74} height={77} />;
	};

	const getBtn = (step) => {
		if (step === "one") return false;
		if (step === "twoGod" || step === "twoBad") return { name: [dictionary["Submit"] || "Submit"], callback: sendSurveyData };
		if (step === "three") return { name: [dictionary["Great!"] || "Great!"], callback: close };
	};

	const ratingItems = [
		{ id: 1, src: "/survey/one.png" },
		{ id: 2, src: "/survey/two.png" },
		{ id: 3, src: "/survey/three.png" },
		{ id: 4, src: "/survey/four.png" },
		{ id: 5, src: "/survey/five.png" },
	];

	const changeRating = (rating) => {
		setRating(rating);
		rating > 3 ? setStep("twoGod") : setStep("twoBad");
	};

	const onClose = () => {
		if (step === "twoGod" || step === "twoBad") sendSurveyData();
		close();
	};

	return (
		<Survey title={getTitle(step)} close={onClose} btn={getBtn(step)} isDisable={isDisable}>
			{step === "one" && (
				<div className={styles.survey_content}>
					<p className={styles.text}>{dictionary["survey_1_text"] || "You have successfully subscribed!"}</p>
					<div className={styles.rating}>
						{ratingItems.map((item) => (
							<button className={styles.rating_item} key={item.id} onClick={() => changeRating(item.id)} aria-label={"rating " + item.id}>
								<Image src={item.src} width={54} height={54} alt={"rating " + item.id} quality={90} />
							</button>
						))}
					</div>
				</div>
			)}
			{step === "twoBad" && <TextareaItem text={dictionary["survey_3_text"] || "How can we enhance subscription process? <span>(Last question)</span>"} value={answer} changeValue={setAnswer} />}
			{step === "twoGod" && (
				<TextareaItem text={dictionary["survey_2_text"] || "Was there something that could prevent you from making a purchase? <span>(Last question)</span>"} value={answer} changeValue={setAnswer} />
			)}

			{step === "three" && (
				<div className={styles.survey_content}>
					<h3 className={styles.title}>{dictionary["thank_you"] || "Thank you!"}</h3>
					<p className={styles.text}>{dictionary["survey_4_text"] || "Your answer was submitted."}</p>
				</div>
			)}
		</Survey>
	);
};

const ImproveSurvey = ({ close = () => {}, dictionary, serviceName = "" }) => {
	const { state } = useStore();
	const { accountData } = state;

	const [step, setStep] = useState("one"); // one, two, three, four
	const [who, setWho] = useState("");
	const [purpose, setPurpose] = useState("");

	const [isDisable, setIsDisable] = useState(false);

	const sendSurveyData = async () => {
		setIsDisable(true);

		const data = { id: accountData.id, who, purpose, service: serviceName };

		await sendSurveyDataToAirtable(data, "tblSRsYBxg1296ubX");

		navigator.clipboard.writeText("HYPETOREALITY");
		setStep("four");
		setIsDisable(false);
	};

	const getTitle = (step) => {
		if (step === "one") return dictionary["survey_5_header"] || "Get a Promo Code!";
		if (step === "two" || step === "three") return <HandySvg src={"/survey/question.svg"} width={77} height={76} />;
		if (step === "four") return <HandySvg src={"/survey/ok.svg"} width={74} height={77} />;
	};

	const getBtn = (step, data) => {
		if (step === "one") return { name: [dictionary["survey_5_but"] || "Go!"], callback: () => setStep("two") };
		if (step === "two") return { name: dictionary["survey_6_but"] || "Next", callback: () => setStep("three"), isDisable: data.who.length < 1 };
		if (step === "three") return { name: [dictionary["Submit"] || "Submit"], callback: sendSurveyData, isDisable: data.purpose.length < 1 };
		if (step === "four") return { name: [dictionary["Great!"] || "Great!"], callback: close };
	};

	const onClose = () => {
		if (step === "three") sendSurveyData();
		close();
	};

	return (
		<Survey title={getTitle(step)} close={onClose} btn={getBtn(step, { who: who, purpose: purpose })} isDisable={isDisable}>
			{step === "one" && (
				<div className={styles.survey_content}>
					<p
						className={styles.text}
						dangerouslySetInnerHTML={{ __html: dictionary["survey_5_text"] || "Answer 2 questions and get a <b>promo code!</b><br />That will help us to enhace BgRem according to your needs." }}
					/>
				</div>
			)}
			{step === "two" && (
				<TextareaItem
					text={dictionary["survey_6_text"] || "Describe who you are and why you use BgRem:"}
					placeholder={dictionary["survey_6_example"] || "Eg. I’m a designer, creating content for a blog."}
					value={who}
					changeValue={setWho}
				/>
			)}
			{step === "three" && <TextareaItem text={dictionary["survey_7_text"] || "What would you enhance in BgRem?"} value={purpose} changeValue={setPurpose} />}
			{step === "four" && (
				<div className={styles.survey_content}>
					<h3 className={styles.title}>{dictionary["thank_you"] || "Thank you!"}</h3>
					<p className={styles.text}>{dictionary["survey_8_text"].replace("X", "20") || "Get your 20 credits promo code:"}</p>
					<div className={styles.promo_code}>
						<div className={styles.promo_code_text}>HYPETOREALITY</div>
						<button onClick={() => navigator.clipboard.writeText("HYPETOREALITY")} className={styles.promo_code_btn}>
							<HandySvg src={"/survey/copy.svg"} width={50} height={50} />
						</button>
					</div>
				</div>
			)}
		</Survey>
	);
};

const QuestionSurvey = ({ close = () => {}, dictionary, page = "" }) => {
	const { state } = useStore();
	const { accountData } = state;

	const [step, setStep] = useState("one"); // one, two
	const [question, setQuestion] = useState("");
	const [email, setEmail] = useState(accountData?.email || "");
	const [name, setName] = useState("");
	const [isDisable, setIsDisable] = useState(false);

	const sendQuestion = async () => {
		setIsDisable(true);
		const data = { id: `${accountData?.id}` || "not authorized", email, name, question, page };

		await sendSurveyDataToAirtable(data, "tbl07PDUujMjX98jW");
		setStep("two");
		setIsDisable(false);
	};

	const getBtn = (step) => {
		if (step === "one") return { name: [dictionary["Submit"] || "Submit"], callback: sendQuestion, isDisable: question.length < 1 && name.length < 1 && email.length < 1 };
		return { name: [dictionary["Great!"] || "Great!"], callback: close };
	};

	const topText = dictionary["support_subhead"] || "We will be happy to answer all your questions!";
	const question1 = {
		text: dictionary["support_question1"] || "What’s your name?",
		placeholder: dictionary["support_example1"] || "Emma Carlson",
	};
	const question2 = {
		text: dictionary["support_question2"] || "What’s your email?",
		placeholder: dictionary["support_explanation2"] || "example@domain.com",
	};
	const question3 = {
		text: dictionary["support_question3"] || "How can we help you?",
		placeholder: dictionary["support_explanation3"] || "Your question and coments",
	};

	return (
		<Survey close={close} btn={getBtn(step)} position={"question"} isDisable={isDisable}>
			{step === "one" ? (
				<>
					<div className={styles.survey_content}>
						<h2 className={styles.title}>
							{dictionary["hello"] || "Hello!"}
							<HandySvg src={"/survey/hello.svg"} width={36} height={36} />
						</h2>
						<p className={styles.text}>{topText}</p>
					</div>
					<InputItem {...question1} value={name} changeValue={setName} />
					<InputItem autoFocus={false} {...question2} type={"email"} value={email} changeValue={setEmail} />
					<TextareaItem autoFocus={false} {...question3} value={question} changeValue={setQuestion} />
				</>
			) : (
				<div className={styles.survey_content}>
					<h2 className={styles.title}>{dictionary["thank_you"] || "Thank you!"}</h2>
					<p className={styles.text}>{dictionary["your_request_was_submitted"] || "Your request was submitted."}</p>
				</div>
			)}
		</Survey>
	);
};

const ShareSurvey = ({ close = () => {}, dictionary }) => {
	useEffect(() => {
		bodyHiddenScroll();

		return () => {
			bodyActiveScroll();
		};
	}, []);
	return (
		<Survey close={close} btn={{ name: [dictionary["Ok, thanks"] || "Ok, thanks!"], callback: close }} position={"servey"} isDisable={false}>
			<div className={styles.survey_content}>
				<h2 className={styles.title}>{dictionary["thank_you"] || "Thank you!"}</h2>
				<p className={styles.text}>
					{dictionary["share_art"].replace("100", "30") || "Share art generated in BgRem on your social media account, send us the link to the post at support@bgrem.ai and get 30 credits for free!"}
				</p>
			</div>
		</Survey>
	);
};

export { PaymentSurvey, ImproveSurvey, QuestionSurvey, ShareSurvey };
